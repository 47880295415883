// ---------------------------------------------------------------------------
// Font Variables

$font_regular: "Lora", "Georgia", serif;
$font_base: $font_regular;
$font_light: "Montserrat", "HelveticaNeue", Helvetica, Arial, sans-serif;

// ---------------------------------------------------------------------------
// Type Sizes

$type-XS: 10px;
$type-S: 12px;
$type-SM: 14px;
$type-M: 16px;
$type-ML: 18px;
$type-L: 18px;
$type-XL: 18px;
$type-XXL: 26px;

$base-font-size: $type-M;
$base-font-weight: normal;
$base-line-height: $type-ML;
