// ---------------------------------------------------------------------------
// Component: Header

@import "util/util";

.header,
.header--home {
  margin-bottom: $size-L;
  border-top: 16px solid $red;

  &__body {
    padding: 0 $page--gutter-width-S;

    @include breakpoint(XL) {
      padding: 0 $page--gutter-width-XL;
    }
  }
}

.header {
  border-bottom: 1px solid $red;
  background-color: $white;

  &__title {
    margin-top: 0;
    padding-top: 3rem;
  }

  &__sub-title {
    display: block;
    margin: 0;
    font-family: $font_cover2;
    font-style: normal;
    font-weight: bold;
    padding: $size-base 0;
    color: $red;
    text-transform: uppercase;
    letter-spacing: 0.1rem;

    &:hover {
      color: $red;
      text-decoration: none;
      font-weight: 700;
    }

    &:focus {
      color: $red;
    }

    .title {
      color: $grey-cover;
    }
  }
}

.header--home {
  &__body {
    max-width: $page--max-width;
    margin-left: auto;
    margin-right: auto;
  }

  &__title {
    margin: 0;
    font-family: $font_cover2;
    font-style: normal;
    font-weight: bold;
    padding: 0;
    color: $red;
    text-transform: uppercase;
    letter-spacing: 0.1rem;

    @include breakpoint(L) {
      font-size: $type-XXL;
    }
  }

  .title {
    color: $white;
  }
}

.header-resume {
  @include clearfix();
  vertical-align: baseline;

  .header__body {
    display: flex;
    align-items: baseline;
  }

  &__title {
    margin-bottom: 0;
  }

  &__sub-title {
    font-style: italic;
  }

  .header__title,
  .header__portfolio {
    display: inline-block;
    width: 50%;
    flex: 1 1 0;
  }

  .header__portfolio {
    @include type-body($font_light);
    margin: 0;
  }
}

.header-case-study {
  &__title {
    margin-bottom: $size-S;
  }

  &__project {
    margin-top: 0;
  }

  &__url {
    @include type-xsmall($font_light);
    text-transform: lowercase;

    @include breakpoint(MLL) {
      @include type-body($font_light);
      float: right;
      margin-top: 1.2rem;
    }
  }
}
