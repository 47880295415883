// ---------------------------------------------------------------------------
// Sizing

$size-base  : 4px;
$size-S     : $size-base * 2;  // 8px
$size-SM    : $size-base * 3;  // 12px
$size-M     : $size-base * 4;  // 16px
$size-ML    : $size-base * 6;  // 24px
$size-L     : $size-base * 9;  // 36px
$size-XL    : $size-ML * 2; // 48px

