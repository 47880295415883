// ---------------------------------------------------------------------------
// Typography

// Fonts
@import url("https://fonts.googleapis.com/css?family=Lora|Montserrat|Libre+Franklin|Domine");

// Normalization to start
html {
  @include font-smoothing(on);
  font-size: $base-font-size;
  text-size-adjust: none;
}

body {
  @include type-body();
  margin-bottom: 0;
}

*[dir="ltr"] {
  direction: ltr;
  unicode-bidi: embed;
}
*[dir="rtl"] {
  direction: rtl;
  unicode-bidi: embed;
}
bdo[dir="ltr"] {
  direction: ltr;
  unicode-bidi: bidi-override;
}
bdo[dir="rtl"] {
  direction: rtl;
  unicode-bidi: bidi-override;
}

h1 {
  @include type-1();
}

h2 {
  @include type-2();
}

h3 {
  @include type-3();
}

h4 {
  @include type-4();
}

h5 {
  @include type-5();
}

h6 {
  @include type-6();
}

h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a {
  font-weight: inherit;
  text-decoration: none;
}

h5 + p {
  margin-top: 0;
}

p {
  @include type-body();
  max-width: 48rem;
}

.small {
  @include type-small();
}

.fine-print {
  @include type-xsmall();
}

em,
i {
  font-style: italic;
}

strong,
b {
  font-weight: bold;
}

hr {
  margin: 0.5rem 0;
  height: 1px;
  border: 1px solid $grey-medium;
}

blockquote {
  @include type-6();
  color: $color_base;
  padding-left: 1.8rem;
  margin: 2rem auto;
}

.enlarge {
  @include type-4();
  line-height: $base-line-height * 1.3;
}
