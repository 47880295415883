// ---------------------------------------------------------------------------
// Base: Images - Responsive

p img {
  margin: 0;
}

img {
  max-width: 100%;
  height: auto;
  width: auto;
}

.img {
  &--2x {
    background-size: 50%;
  }

  &--box {
    box-shadow: 0 0 10px 1px rgba(0,0,0,.15);
  }

  &--align-right {
    justify-content: flex-end;
    text-align: right;
    align-items: right;
  }

  &__SM {
    width: 187.5px;
  }

  &__M {
    width: 284px;
  }

  &__ML {
    width: 384px;
  }

  &__XL {
    width: (1024px /2);
  }
}

.img--reduce.img__XL {
  width: (1024px / 2);
}

.figure-frame {
  padding: 0.5rem;
  background-color: $white;
  text-align: center;
}


