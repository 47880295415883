// ---------------------------------------------------------------------------
// Layout

$layout-nav_switch: 'MLL';

// ---------------------------------------------------------------------------
// Layout
$page--max-width          : 75em;
$page--gutter-width-S     : 0.8rem;
$page--gutter-width-XL    : 1.8rem;

$primary-nav--height      : 236px;
