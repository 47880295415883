// ---------------------------------------------------------------------------
// Layout: Case Study

@import "util/util";

.l-case_studies,
.l-case_study {
  .g-row--bleed {
    margin-left: -#{$page--gutter-width-S};
    margin-right: -#{$page--gutter-width-S};

    @include breakpoint(XL) {
      margin-left: -#{$page--gutter-width-XL};
      margin-right: -#{$page--gutter-width-XL};
    }
  }

  .g-cell-content {
    margin-bottom: 1rem;
  }

  .g-B--full {
    margin-bottom: $size-M;
  }

  .img--1x,
  .img--2x {
    box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.15);
  }
}

.l-case_studies {
  .t-usajobs {
    background-color: $color_usajobs;
  }
  .t-cisco {
    background-color: $color_cisco;
  }
  .t-marriott {
    background-color: $color_marriott;
  }
  .t-heritage {
    background-color: $color_heritage;
  }

  .case-study-spread {
    margin-bottom: 0;

    a:hover {
      text-decoration: none;
    }

    .list--media-object {
      padding: 2.5rem 1.5rem;
    }

    &__title,
    &__project-title {
      color: $white;
    }

    &__title {
      margin-bottom: 0;
      text-transform: inherit;
    }

    &__project-title {
      margin-top: 0.5rem;
    }

    &__feature-img {
      @include breakpoint(SM) {
        width: 128px;
      }
    }
  }
}

.l-case_study {
  :focus {
    outline: 0;
  }

  .g-row--bleed {
    padding-right: 1rem;

    @include breakpoint(XL) {
      padding-left: 1.8rem;
      padding-right: 1.8rem;
    }
  }

  .g-row--painted.is-feature {
    background-color: $grey-lighter;
  }

  .enlarge {
    margin-top: 0;
  }

  .caption {
    @include type-xsmall();
  }

  .list--bullet {
    max-width: 48rem;
  }

  .case_study-section__header-img {
    text-align: center;

    @include breakpoint(ML) {
      text-align: right;
    }
  }

  .case_study-section__header:first-child {
    margin-top: 0;
  }

  @include breakpoint(SM) {
    &__photo-pair-container {
      display: flex;
    }

    &__photo-pair {
      flex: 1;
      margin-top: 0;

      &.is-img-XS {
        max-width: 144px;
      }

      &.is-img-S {
        max-width: 225px;
      }

      &.is-img-L {
        max-width: 500px;
      }

      &.is-left {
        margin-right: $size-L;
      }

      &.is-right {
        margin-left: $size-L;
      }
    }
  }
}
