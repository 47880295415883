// ---------------------------------------------------------------------------
// Layout: Print version of Resume
@import url("https://fonts.googleapis.com/css?family=Roboto:300");

.l-resume,
.l-federal-resume {
  @import "util/util-print";
  @import "base/typography";

  background-color: $white;

  p,
  .section_body,
  .notes {
    font-family: Roboto, sans-serif;
    font-weight: 300;
    font-size: $base-font-size;
  }

  .notes {
    font-size: $type-XS;

    &--extended {
      padding: 0;
    }
  }

  .l-shell {
    border: 0;
  }

  .content-well {
    padding-bottom: 0.5rem;

    ul {
      padding-left: 0;
    }
  }

  #profile {
    .section_title {
      display: none;
    }
    .section_body {
      padding-top: 0.5rem;

      &.first {
        margin-bottom: 0;
      }
    }
  }

  .footer {
    padding: 0;

    .contact-card {
      height: auto;
      min-width: inherit;
      max-width: inherit;
      border-radius: 0;
      margin-left: 0.8rem;
      margin-right: 0.8rem;
    }

    .contact-card__body {
      height: auto;
      box-shadow: none;
      -webkit-transform: rotateY(180deg);
      -moz-transform: rotateY(180deg);
      -ms-transform: rotateY(180deg);
      transform: rotateY(180deg);
    }

    .contact-card__front {
      display: none;
    }

    .contact-card__back {
      height: auto;
      padding: 0.5rem 0 0 0;
    }

    .contact-card__flip-revert {
      display: none;
    }

    .contact-card__profile-list li.full-width {
      display: inline-block;
      float: left;
      width: 50%;
    }

    .contact-card__profile-list li {
      display: none;
    }

    .contact-info {
      margin-bottom: 0;
      font-family: Roboto, sans-serif;
      font-weight: 300;
    }
  }

  // Have to get everything onto a page so remove header margins
  h2,
  h3,
  h4,
  h5 {
    margin: 0;
  }

  h4 {
    line-height: 1.3rem;
  }

  h5 {
    margin-bottom: 0;
    line-height: 1.2rem;
  }

  // Hide the menu
  .primary-nav,
  .header__nav-toggle {
    display: none;
  }

  .header__title {
    font-size: $type-XXL;
    padding: 0;
    margin: $size-base 0 0;
  }

  .header {
    margin-bottom: 0;

    @include breakpoint(MLL) {
      border-bottom: 1px solid $red;
    }
  }

  .header-resume__sub-title {
    margin-bottom: 0.4rem;
  }

  .section_title {
    margin-top: 0;
    margin-bottom: 0;

    &.first {
      margin-top: 0;
    }
  }

  .section_body {
    @include clearfix();
  }

  p {
    max-width: inherit;
    margin-bottom: 0.5rem;
  }

  .enlarge {
    @include type-5();
    margin: 0 0 0.5rem;
    line-height: $base-line-height * 1.1;
  }

  #technical {
    display: none;
  }

  // Hide Skills
  .skills {
    display: none;
  }

  .job {
    @include clearfix();
    margin-bottom: 0.8rem;

    &:last-child {
      margin-bottom: 0;
    }

    h3 {
      padding-top: 0;
      border-top: 0;
    }

    h4,
    h5 {
      font-family: Roboto, sans-serif;
      font-weight: 300;
    }

    &__details {
      float: left;
      width: 33%;
    }
    &__timespan {
      display: block;
    }
    &__org {
      display: none;
    }
  }

  #railsdog {
    display: none;
  }

  .location {
    display: none;
  }

  // Hide accomplishment details
  .accomplishments {
    float: right;
    width: 65%;
    margin-left: 2%;

    &.print-hide {
      display: none;
    }

    li {
      font-family: Roboto, sans-serif;
      font-weight: 300;
      @include lineHeight($base-line-height);
      list-style-type: disc;
      margin-left: 1.25rem;
      margin-bottom: $size-S;
    }

    li.accomplishment_desc {
      display: inherit;
      margin-top: 0.5rem;
      list-style-type: none;
      margin-left: 0;
      margin-top: 0;
      margin-bottom: $size-base;
    }
  }

  .education__list {
    margin-top: 0;

    dd {
      margin: 0;
    }
  }
}

.l-resume {
  .accomplishments {
    li {
      display: none;
    }
  }
}
