// ---------------------------------------------------------------------------
// Layout: Resume
@import "util/util";

.l-resume {
  /* ----------------------------- Base -----------------------------------*/
  .content-well {
    ol,
    ul {
      padding-left: 1rem;
    }

    p,
    ol,
    ul {
      padding-bottom: 6px;
    }

    ul li {
      margin-left: 10px;
      list-style-type: circle;
    }

    dl dd {
      margin: 0;
    }
  }

  /* ----------------------------- Header -----------------------------------*/
  .enlarge {
    margin-top: 0;
  }

  /* ----------------------------- Sections -----------------------------------*/
  section {
    @include clearfix();
  }

  .section_title {
    margin-bottom: 0.5em;

    @include breakpoint(ML) {
      float: left;
      width: 25%;
      margin-bottom: 0;
      padding: 2em 0;
    }

    h2 {
      @include breakpoint(ML) {
        margin-top: 0;
        font-size: $type-L;
        line-height: 1.4;
      }
    }
  }

  .section_body {
    @include clearfix();
    max-width: 850px;

    @include breakpoint(ML) {
      float: right;
      width: 73%;
      margin-left: 2%;
      margin-bottom: 1em;
      padding-top: 2em;
      padding-bottom: 1em;
      border-top: 1px solid $grey-darkest;
    }

    &.first {
      border-top: 0;
    }
  }

  /* ----------------------------- Skills -----------------------------------*/
  .skills-list li {
    margin: 3px 0;
    padding: 3px 0;
  }

  .skills-list li span {
    display: block;
    font-size: 152%;
    margin-bottom: -2px;
    padding: 0;
  }

  .skills div.section_body dl {
    @include breakpoint(MML) {
      float: left;
      width: 33%;
      margin: 0;
      padding-left: 15px;
    }
  }

  .skills div.section_body dl:first-child {
    padding-left: 0;
  }

  .skills div.section_body dl dt {
    @include type-5($font_light);
    margin-top: 0;
  }

  /* ----------------------------- Technical -----------------------------------*/
  .content-well .talent {
    margin: 0;
    padding-bottom: 0;

    @include breakpoint(SM) {
      float: left;
      width: 33%;
    }
  }

  .talent h2 {
    margin-bottom: 6px;
  }

  /* ----------------------------- Experience -----------------------------------*/
  .job {
    position: relative;
    margin-bottom: 1.5em;
  }

  .job .location {
    @include type-6();
    margin-top: 0;
  }

  .job h3 {
    text-transform: inherit;
    border-top: 1px solid #ccc;
    padding-top: 1em;
    margin-bottom: 0;
  }

  .first h3 {
    border-top: 0px;
    margin-top: 0;
    padding-top: 0;
  }

  .content-well .accomplishment_desc {
    list-style-type: none;
    margin-left: -1em;
    margin-top: 1em;
    margin-bottom: calculateRem($base-line-height / 2);
  }

  #experience ul li ol li {
    list-style-type: disc;
  }

  .notes,
  ol.notes li {
    @include type-small();
  }

  .notes {
    padding-bottom: 2px;
  }

  .notes--extended {
    padding: 1rem 0 0;
  }
}
