// ---------------------------------------------------------------------------
// Base: Shell
html,
body {
  height: 100%;
}

html {
  box-sizing: border-box;
}

body {
  background-color: $grey-cover;

  :focus {
    outline: 2px dotted $grey-light;
    outline-offset: 3px;
  }
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

.l-shell {
  z-index: 1;
  max-width: $page--max-width;
  height: 100%;
  margin: 0 auto;
  padding: 0;
  background: $white;
  border: solid $grey-cover;
  border-width: 8px 0 2px 0;
  transition: 0.3s ease all;
  transform: translateX(0);
  transform: translate3d(0, 0, 0);
}

.content-well {
  padding: 0 $page--gutter-width-S 3rem;
  background: $white;

  @include breakpoint(XL) {
    padding: 0 $page--gutter-width-XL 3rem;
  }

  :focus {
    outline: 0;
  }
}

body.is-pushed {
  .l-shell {
    transform: translateY($primary-nav--height);
    transform: translate3d(0, $primary-nav--height, 0);
  }
}

.l-site-nav {
  margin-top: $size-ML;
  padding: $size-M;
}
