// ---------------------------------------------------------------------------
// Component: Footer

@import "util/util";

.footer {
  @include clearfix();
  margin: 0;
  padding: 1rem 0;
  border-top: 1px solid $grey-light;
  background-color: $white;

  .contact-card {
    padding: 0 $page--gutter-width-S;

    @include breakpoint(XL) {
      padding: 0 $page--gutter-width-XL;
    }
  }
}
