// ---------------------------------------------------------------------------
// Component: Navigation

@import "util/util";

.primary-nav {
  &:focus {
    outline: 0;
  }

  &__body {
    padding: 0;

    li {
      margin: 0;
    }
  }
}

.primary-nav,
.primary-nav--home {
  border-top: 8px solid $red;

  &:focus {
    outline: 0;
  }

  &__a {
    position: relative;
    display: block;
    width: 100%;
    @include nav-4();
    margin: 0;
    padding: $size-M $size-M ($size-M - 7.125px) 0;
    color: $red;
    line-height: 1.25;
    border-bottom: 7.125px solid transparent;

    @include breakpoint(M) {
      @include nav-2();
      margin: 0;
      padding: $size-SM $size-SM $size-SM 0;
      color: $red;
    }

    &:hover {
      color: $red;
      text-decoration: none;
      border-bottom-color: inherit;
    }

    &:focus {
      color: $red;
    }

    &::before {
      content: "/";
      padding-right: $size-SM;
    }

    &.is-first {
      @include breakpoint(SM) {
        padding-left: $size-M;
      }

      @include breakpoint(M) {
        padding-left: $size-SM;
      }

      &::before {
        content: "";
      }
    }

    &.is-current {
      font-weight: bold;
    }
  }

  .list-item {
    display: inline-block;
    color: $red;
  }

  &__body {
    padding: 0;

    li {
      margin: 0;
    }
  }
}

.primary-nav--home {
  &__body {
    max-width: $page--max-width;
    margin-left: auto;
    margin-right: auto;
  }
}

.case-study-nav__body {
  @include type-body($font_light);
  @include lineHeight($base-line-height + 2px);
}

.home-nav__body {
  @include type-body($font_cover2);
  @include lineHeight($base-line-height + 2px);
}

.case-study-nav__title {
  &.is-current {
    padding-left: $size-base;
  }
}

.home-nav__header,
.case-study-nav__header {
  font-weight: bold;
}

// home-nav
$home-nav-item-margin: $size-M;
$home-nav-item-margin-L: $size-L;

.home-nav {
  display: flex;
  flex-wrap: wrap;
  margin: 0 auto;
  padding: 0;

  &__container {
    max-width: $page--max-width;
    margin-left: auto;
    margin-right: auto;
  }

  &__header {
    font-family: $font_cover;
    @include fontSize($type-L);
    color: $white;
    text-transform: uppercase;
  }

  &__item {
    position: relative;
    width: 100%;
    margin-bottom: $home-nav-item-margin;

    @include breakpoint(M) {
      flex: 1 1 40%;
      margin-right: $home-nav-item-margin;
    }

    @include breakpoint(L) {
      margin-right: $home-nav-item-margin-L;
      margin-bottom: $home-nav-item-margin-L;
    }
  }

  &__item:nth-child(2n) {
    @include breakpoint(M) {
      margin-right: 0;
    }
  }

  &__image-container {
    position: relative;
    padding-bottom: 66.66667%;
  }

  &__image {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    height: 100%;
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: cover;
    border-radius: 8px;
    width: 100%;
  }

  &__text-supporting {
    display: flex;
    align-items: center;
    justify-content: space-between;
    line-height: 24px;
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 48px;
    padding: 0 16px;
    background: rgba(38, 38, 38, 0.6);
    color: $white;
    font-family: $font_cover;
  }

  &__item a {
    display: block;
  }
}

.home-nav--small {
  .home-nav__item {
    @include breakpoint(M) {
      flex: 1;
    }
  }
  .home-nav__item:nth-child(2n) {
    @include breakpoint(M) {
      margin-right: $home-nav-item-margin;
    }
    @include breakpoint(L) {
      margin-right: $home-nav-item-margin-L;
    }
  }
}

.site-nav {
  margin-top: $size-L;
  padding: 1rem;
}
